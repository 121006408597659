var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "lista-proposta" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0 proposta" },
        [
          _c("breadcrumb", {
            attrs: { title: "Propostas", actualPage: "Lista de propostas" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "grid grid-cols-11 mt-8 mb-6 w-full" }, [
        _c(
          "div",
          { staticClass: "col-start-1 ml-3" },
          [
            _c(
              "filtro",
              {
                ref: "filtro",
                attrs: { titleName: "Propostas", isFilter: _vm.filtro },
              },
              [
                _c(
                  "div",
                  { staticClass: "pt-2 pb-6 px-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-col md:flex-row" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full md:w-3/5 md:pr-3",
                          attrs: {
                            size: "large",
                            "label-placeholder": "Nro da Proposta",
                          },
                          model: {
                            value: _vm.filterValue.number,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterValue, "number", $$v)
                            },
                            expression: "filterValue.number",
                          },
                        }),
                        _c("v-select", {
                          staticClass: "w-full mt-8 md:w-2/5 md:mt-4",
                          attrs: {
                            placeholder: "Status da Proposta",
                            "show-no-options": false,
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            label: "name",
                            options: _vm.proposalStatusOptionsMixin,
                            size: "large",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setProposalStatusOption(
                                _vm.selectedProposalStatusOption
                              )
                            },
                          },
                          model: {
                            value: _vm.selectedProposalStatusOption,
                            callback: function ($$v) {
                              _vm.selectedProposalStatusOption = $$v
                            },
                            expression: "selectedProposalStatusOption",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-col mt-4 md:mt-8 md:flex-row" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full mt-4 md:w-3/5 md:pr-3 md:m-0",
                          attrs: {
                            size: "large",
                            "label-placeholder": "Nome do Tomador",
                          },
                          model: {
                            value: _vm.filterValue.policyHolderName,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterValue, "policyHolderName", $$v)
                            },
                            expression: "filterValue.policyHolderName",
                          },
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mt-8 md:w-2/5 md:m-0",
                          attrs: {
                            size: "large",
                            "label-placeholder": "CNPJ Tomador",
                          },
                          model: {
                            value: _vm.filterValue.policyHolderCpfCnpj,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filterValue,
                                "policyHolderCpfCnpj",
                                $$v
                              )
                            },
                            expression: "filterValue.policyHolderCpfCnpj",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex mt-4 md:mt-8 flex-col md:flex-row" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full mt-4 md:w-3/5 md:pr-3 md:m-0",
                          attrs: {
                            size: "large",
                            "label-placeholder": "Nome do Segurado",
                          },
                          model: {
                            value: _vm.filterValue.insuredName,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterValue, "insuredName", $$v)
                            },
                            expression: "filterValue.insuredName",
                          },
                        }),
                        _c("vs-input", {
                          staticClass: "w-full mt-8 md:w-2/5 md:m-0",
                          attrs: {
                            size: "large",
                            "label-placeholder": "CNPJ Segurado",
                          },
                          model: {
                            value: _vm.filterValue.insuredCpfCnpj,
                            callback: function ($$v) {
                              _vm.$set(_vm.filterValue, "insuredCpfCnpj", $$v)
                            },
                            expression: "filterValue.insuredCpfCnpj",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "flex flex-row mt-5 w-3/5" }, [
                      _c("h6", [_vm._v("Data de Cadastro da Proposta")]),
                    ]),
                    _c("div", { staticClass: "flex flex-row mt-4" }, [
                      _c("div", { staticClass: "w-full flex" }, [
                        _c(
                          "span",
                          { staticClass: "flex-col w-1/2" },
                          [
                            _c("p", [_vm._v("Data inicial")]),
                            _c("datepicker", {
                              staticClass: "pr-4 w-full calendar-initial-date",
                              attrs: {
                                language: _vm.language,
                                format: "dd/MM/yyyy",
                              },
                              model: {
                                value: _vm.filterValue.startDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValue, "startDate", $$v)
                                },
                                expression: "filterValue.startDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "flex-col w-1/2" },
                          [
                            _c("p", [_vm._v("Data final")]),
                            _c("datepicker", {
                              staticClass: "pr-4 w-full calendar-final-date",
                              attrs: {
                                language: _vm.language,
                                format: "dd/MM/yyyy",
                              },
                              model: {
                                value: _vm.filterValue.endDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterValue, "endDate", $$v)
                                },
                                expression: "filterValue.endDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-6",
                            attrs: { "vs-lg": "6", "vs-sm": "12" },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "label-content label",
                                staticStyle: { "margin-left": "-12px" },
                              },
                              [
                                _vm._v(
                                  "\n                Tipo de proposta:\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "container-element pt-6",
                            attrs: { "vs-lg": "6", "vs-sm": "12" },
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "label-content label",
                                staticStyle: { "margin-left": "-12px" },
                              },
                              [
                                _vm._v(
                                  "\n                Tipo endosso:\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "flex justify-between pt-2",
                            attrs: { "vs-lg": "6", size: "small" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Selecione" },
                                on: {
                                  change: function ($event) {
                                    return _vm.typeChange()
                                  },
                                },
                                model: {
                                  value: _vm.filterValue.proposalType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterValue,
                                      "proposalType",
                                      $$v
                                    )
                                  },
                                  expression: "filterValue.proposalType",
                                },
                              },
                              _vm._l(_vm.typesProposal, function (item) {
                                return _c("el-option", {
                                  key: item.Id,
                                  attrs: { label: item.label, value: item.Id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "flex justify-between pt-2",
                            attrs: { "vs-lg": "6", size: "small" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Selecione" },
                                on: {
                                  change: function ($event) {
                                    return _vm.typeChangeEndosso()
                                  },
                                },
                                model: {
                                  value: _vm.filterValue.endossoType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filterValue,
                                      "endossoType",
                                      $$v
                                    )
                                  },
                                  expression: "filterValue.endossoType",
                                },
                              },
                              _vm._l(_vm.subtiposEndosso, function (item) {
                                return _c("el-option", {
                                  key: item.Id,
                                  attrs: { label: item.Name, value: item.Id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-row-reverse mt-4" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "ml-4",
                            attrs: { color: "primary", type: "filled" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.filterProposals(true)
                              },
                            },
                          },
                          [_vm._v("Aplicar filtro")]
                        ),
                        _c(
                          "vs-button",
                          {
                            attrs: {
                              color: "primary",
                              type: "filled",
                              icon: "clear",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.clearFilter()
                              },
                            },
                          },
                          [_vm._v("Limpar filtro")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-start-10 col-span-4 divDropdown" },
          [
            _c("OnpointDropdown", {
              staticClass: "dropdown-menu-proposta-endosso col-span-2",
              attrs: {
                label: "Nova proposta ou Endosso",
                auxiliarText: "Selecione o tipo de proposta que deseja iniciar",
                menuItems: _vm.dropdownItemsMenuPropostaEndosso,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticStyle: { "word-wrap": "break-word" },
          attrs: {
            title: "",
            visible: _vm.modalDocuments,
            width: "50%",
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalDocuments = $event
            },
          },
        },
        [
          _c("h3", [
            _vm._v(
              "\n      Você ainda tem documentos pendentes e/ou aguardando análise pela\n      seguradora\n    "
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.goTO("/corretor")
                    },
                  },
                },
                [_vm._v("Ir para dados da corretora")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "word-wrap": "break-word", "padding-bottom": "150px" },
          attrs: {
            title: "",
            visible: _vm.modalDocumentsAwait,
            width: "50%",
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalDocumentsAwait = $event
            },
          },
        },
        [
          _c(
            "h3",
            { staticStyle: { "padding-bottom": "15px", color: "#0e65a5" } },
            [
              _vm._v(
                "\n      Cadastro concluído, aguarde a aprovação da Seguradora!\n    "
              ),
            ]
          ),
          _c("el-alert", {
            attrs: {
              title: "Atenção!",
              type: "warning",
              description:
                "Para ter acesso a plataforma aguarde a liberação do cadastro pela seguradora!",
              "show-icon": "",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer text-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "vs-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onLogout()
                    },
                  },
                },
                [_vm._v("\n        Sair\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-width-auto",
          attrs: {
            title: "Agurdando análise pela seguradora!",
            active: _vm.modalAnalise,
          },
          on: {
            "update:active": function ($event) {
              _vm.modalAnalise = $event
            },
          },
        },
        [
          _c("p", { staticStyle: { padding: "6px" } }, [
            _vm._v(
              "\n      Aguarde a aprovação da seguradora para continuar com a proposta\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "w-full sm:w-7/12 sm:float-right",
              attrs: { "router-link": "", to: "/corretor" },
              on: {
                click: function ($event) {
                  _vm.modalAnalise = false
                },
              },
            },
            [_vm._v("\n      Ir para dados da corretora\n    ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content mt-2" },
        [
          _vm.proposals.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    staticClass: "table-list",
                    attrs: {
                      data: _vm.proposals || [],
                      noDataText: "Nenhuma proposta encontrada",
                      hoverFlat: true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: tr } },
                                [
                                  _c(
                                    "vs-td",
                                    { staticClass: "text-center w-1/12" },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "btn-action-proposta",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass:
                                                "btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5",
                                            },
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.goToProposal(
                                                        data[indextr]
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-base px-6 py-2",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            data[indextr]
                                                              .StatusId == 1
                                                              ? "Visualizar proposta"
                                                              : "Visualizar proposta"
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              data[indextr].TypeName ===
                                                "Renovação" &&
                                              _vm.canCancelItem(
                                                data[indextr].StatusId
                                              )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelarRenovacao(
                                                            data[indextr]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Cancelar proposta"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm.canCancelItem(
                                                    data[indextr].StatusId
                                                  )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.disclamerCancel(
                                                            data[indextr]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data[indextr]
                                                                  .StatusId == 1
                                                                  ? "Cancelar proposta"
                                                                  : "Cancelar proposta"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.canDownloadMinute(
                                                data[indextr].StatusId,
                                                data[indextr]
                                                  .PolicyHolderCanViewLimitsAndRates
                                              )
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadMinuteClick(
                                                            data[indextr]
                                                              .UniqueId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data[indextr]
                                                                  .StatusId == 1
                                                                  ? "Imprimir proposta"
                                                                  : "Imprimir minuta"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].Number },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(data[indextr].Number)
                                            ),
                                          ]),
                                          data[indextr].StatusId === 6
                                            ? _c(
                                                "vx-tooltip",
                                                {
                                                  attrs: {
                                                    text: "Proposta ainda não se encontra para análise da seguradora. Verifique as pendências para seguir com o processo de emissão",
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons alert text-xl ml-1",
                                                    },
                                                    [_vm._v("info")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].TypeId },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(_vm._s(data[indextr].TypeName)),
                                      ]),
                                    ]
                                  ),
                                  _vm.podeExibirCanal
                                    ? _c(
                                        "vs-td",
                                        {
                                          staticClass: "text-center w-1/4",
                                          attrs: {
                                            data: data[indextr].NomeTipoCanal,
                                          },
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                data[indextr].NomeTipoCanal
                                                  ? data[indextr].NomeTipoCanal
                                                  : "Canal não definido"
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/5",
                                      attrs: {
                                        data: data[indextr].PolicyHolderCpfCnpj,
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].PolicyHolderName)
                                        ),
                                      ]),
                                      _c("small", { staticClass: "block" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("maskCpfCnpj")(
                                                data[indextr]
                                                  .PolicyHolderCpfCnpj
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/5",
                                      attrs: {
                                        data: data[indextr].InsuredCpfCnpj,
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].InsuredName)
                                        ),
                                      ]),
                                      _c("small", { staticClass: "block" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("maskCpfCnpj")(
                                                data[indextr].InsuredCpfCnpj
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: {
                                        data: data[indextr].ModalityName,
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(data[indextr].ModalityName)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].StatusName },
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "status",
                                          attrs: {
                                            type: "filled",
                                            size: "small",
                                            color: _vm._f("setColorStatus")(
                                              data[indextr].StatusId,
                                              data[indextr].TimelineStatusId
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("setLabelStatus")(
                                                  data[indextr].StatusId
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: { data: data[indextr].CreateDate },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              new Date(
                                                data[indextr].CreateDate
                                              ),
                                              "DD/MM/YYYY - HH:mm"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center w-1/12",
                                      attrs: {
                                        data: data[indextr].AwardAmount,
                                      },
                                    },
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "input-currency font-semibold text-center",
                                        attrs: { disabled: "true" },
                                        model: {
                                          value:
                                            data[indextr].InsuredAmountValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data[indextr],
                                              "InsuredAmountValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data[indextr].InsuredAmountValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      1416586253
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th"),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "numero" },
                          },
                          [_vm._v("Nro.")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "numero" },
                          },
                          [_vm._v("Tipo")]
                        ),
                        _vm.podeExibirCanal
                          ? _c(
                              "vs-th",
                              {
                                staticClass: "text-center",
                                attrs: { "sort-key": "Channel" },
                              },
                              [_vm._v("Canal")]
                            )
                          : _vm._e(),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "policyHolderName" },
                          },
                          [_vm._v("Tomador")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "segurado" },
                          },
                          [_vm._v("Segurado")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "modalidade" },
                          },
                          [_vm._v("Modalidade")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "status" },
                          },
                          [_vm._v("Status")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "dataProposta" },
                          },
                          [_vm._v("Data da proposta")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "is" },
                          },
                          [_vm._v("IS")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("vs-pagination", {
                  staticClass: "mt-5",
                  attrs: { total: _vm.numberOfPages },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "word-wrap": "break-word", "padding-bottom": "150px" },
          attrs: {
            title: "",
            visible: _vm.modalDocsCancelProposal,
            width: "50%",
            "show-close": true,
            "close-on-press-escape": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalDocsCancelProposal = $event
            },
          },
        },
        [
          _c(
            "h3",
            { staticStyle: { "padding-bottom": "15px", color: "#0e65a5" } },
            [
              _vm._v(
                "\n      Solicitação de cancelamento enviada com sucesso!\n    "
              ),
            ]
          ),
          _c("el-alert", {
            attrs: {
              title: "Atenção!",
              type: "warning",
              description:
                "Caso seja necessário, faça o upload de documentos para análise da seguradora!",
              "show-icon": "",
            },
          }),
          _c("div", { staticClass: "content-upload mt-5" }, [
            _vm.actualCancelProposal.TypeId != 4
              ? _c(
                  "p",
                  [
                    _c("b", [_vm._v("Observação")]),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4, maxRows: 6 },
                        placeholder: "Descreva o motivo do cancelamento",
                      },
                      model: {
                        value: _vm.payloadCancelProposal.Observacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.payloadCancelProposal, "Observacao", $$v)
                        },
                        expression: "payloadCancelProposal.Observacao",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "p",
              { staticClass: "text-center" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mt-4",
                    on: {
                      click: function ($event) {
                        return _vm.addDocumentList()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                    _vm._v(" Adicionar Documento\n        "),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "listDocs mt-4" }, [
              _c(
                "ul",
                _vm._l(
                  _vm.payloadCancelProposal.Documents,
                  function (item, index) {
                    return _c("li", { key: index }, [
                      _c("label", [_vm._v(" " + _vm._s(item.DocumentName))]),
                      _c("p", [
                        _vm._v("Arquivos: " + _vm._s(item.files.length)),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.removeDocuments(index)
                          },
                        },
                      }),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer text-center mt-10",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-2",
                  attrs: { color: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.modalDocsCancelProposal = false
                    },
                  },
                },
                [_vm._v("\n        Sair\n      ")]
              ),
              _c(
                "vs-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.sendDocuments()
                    },
                  },
                },
                [_vm._v("\n        Enviar Documentos\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.modalUpload
        ? _c("base64Upload", {
            attrs: { modalUpload: _vm.modalUpload, documentoName: "" },
            on: {
              callBackUpload: _vm.callBackUpload,
              closeModal: _vm.closeModal,
            },
          })
        : _vm._e(),
      _c(
        "Dialog",
        {
          staticClass: "buscarApolice",
          attrs: { visible: _vm.buscarApolice, closable: false },
          on: {
            "update:visible": function ($event) {
              _vm.buscarApolice = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _c("div", { staticClass: "title" }, [
                      _c("p", [_vm._v("Buscar apólice")]),
                    ]),
                    _c("div", { staticClass: "subtitle" }, [
                      _c("p", [
                        _vm._v(
                          "Informe o número da apólice para iniciar o processo de endosso. Digite o número abaixo para continuar."
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "actions-buscar-apolice" },
                    [
                      _c(
                        "Button",
                        {
                          staticClass:
                            "cancel-buscar-apolice p-button-outlined",
                          on: {
                            click: function ($event) {
                              _vm.buscarApolice = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c("Button", { staticClass: "init-buscar-apolice" }, [
                        _vm._v("Iniciar"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "numero-buscar-apolice" }, [
            _c(
              "div",
              [
                _c("p", { staticClass: "numero-buscar-apolice-text" }, [
                  _vm._v("\n            Número da apólice "),
                  _c("i", { staticStyle: { color: "#c2435f" } }, [_vm._v("*")]),
                ]),
                _c("InputText", {
                  staticClass: "input-buscar-apolice",
                  attrs: { type: "text" },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "popup-base",
        {
          staticClass: "success-modal onpoint-successmodal",
          attrs: {
            showModal: _vm.modalcancelationReason,
            size: "sm",
            title: " ",
            "button-close-hidden": false,
          },
          on: {
            close: function ($event) {
              _vm.modalcancelationReason = false
              _vm.cancelationReason = null
            },
          },
        },
        [
          _c("template", { slot: "content" }, [
            _c(
              "div",
              [
                _c("span", { staticClass: "mb-2" }, [
                  _vm._v("Selecione o motivo para o cancelamento\n          "),
                  _c("strong", { staticStyle: { color: "red" } }, [
                    _vm._v("*"),
                  ]),
                ]),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "escolha o motivo" },
                    model: {
                      value: _vm.cancelationReason,
                      callback: function ($$v) {
                        _vm.cancelationReason = $$v
                      },
                      expression: "cancelationReason",
                    },
                  },
                  _vm._l(_vm.cancelationReasonList, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "text-right mt-5 w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    staticStyle: { "margin-right": "5px" },
                    on: {
                      click: function ($event) {
                        _vm.modalcancelationReason = false
                        _vm.cancelationReason = null
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _vm.cancelationReason
                  ? _c(
                      "vs-button",
                      {
                        attrs: { color: "danger" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.disclamerCancelRenovacao()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v("\n            Cancelar proposta\n          "),
                        ]),
                      ]
                    )
                  : _c(
                      "vs-button",
                      { attrs: { disabled: "", color: "danger" } },
                      [
                        _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                          _vm._v(" Cancelar proposta"),
                        ]),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description"),
          ]),
        ]),
        _c("p", [_vm._v("Você ainda não possui nenhuma proposta cadastrada.")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }